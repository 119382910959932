import { getCurrentInstance } from '@vue/composition-api'

/**
 * Returns translated string if i18n package is available to Vue
 * If i18n is not configured then it will simply return what is being passed
 * Useful if you don't know if i18n is configured or not
 * Used in @core files to handle absence of i18n without errors
 * @param {String} key i18n key to use for translation
 */

// old without variables
// export const t = key => {
//   const vm = getCurrentInstance().proxy
//   return vm.$t ? vm.$t(key) : key
// }

// new, with variables https://stackoverflow.com/questions/75606706/vue-i18n-calling-tkey-parameterarray-does-not-fill-in-interpolate-value-of
export const t = (key, args) => {
  const vm = getCurrentInstance().proxy
  return vm.$t ? vm.$t(key, args) : key
}

export const _ = null
